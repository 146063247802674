import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'staging',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.coerce.number().min(0),
    }),
  },
  {
    name: 'Virtual Staging',
    short: 'Digitally add furniture and decor to photos of your property.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/staging/after2.jpg',
    images: [],
  },
);

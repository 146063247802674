import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';
import { SCHEMA_FILE } from '~/lib/schema';

export enum TwtAerialType {
  PHOTO_VIDEO = 'photo_video',
  VIDEO = 'video',
  PHOTO_FULL = 'photo_full',
  PHOTO_MINI = 'photo_mini',
}

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.nativeEnum(TwtAerialType),
      boundaries_photo: z.coerce.number().optional(),
      boundaries_video: z.coerce.number().optional(),
      instructions: z.string().optional(),
      survey: SCHEMA_FILE.optional(),
      pin_text: z
        .object({
          location: z.string(),
          type: z.union([z.literal('photo'), z.literal('video'), z.literal('both')]),
        })
        .array()
        .optional()
        .default([]),
    }),
  },
  {
    name: 'Aerial',
    short: 'Capture a bird’s eye view with one of our drone experts to enhance the marketing of your property.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail(context) {
      if (context['type'] === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/aerial/commercial.jpg';
      }

      return 'tenant/twt/aerial/mini.jpg';
    },
    images: [],
  },
);

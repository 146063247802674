import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import TwtStagingConfig from '~/tenants/twt/performable/staging/TwtStagingConfig';
import TwtVirtualConfig from '~/tenants/twt/performable/virtual/TwtVirtualConfig';
import TwtEditingConfig from '~/tenants/twt/performable/editing/TwtEditingConfig';

export enum TwtPhotoType {
  INTERIOR_EXTERIOR = 'interior_exterior',
  EXTERIOR_ONLY = 'exterior_only',
  RESHOOT = 'reshoot',
}

export default createPerformableConfig(
  'photo',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.nativeEnum(TwtPhotoType),
      twilight: coerceBoolean().optional(),
      neighborhood: z.string().array().optional(),
      neighborhood_custom: z.object({ location: z.string() }).array().optional(),
      highlights: coerceBoolean().optional(),
      domain: z.string().optional(),
      photos: z.coerce.number().optional(),
    }),
  },
  {
    name: 'Photo Tour',
    thumbnail: 'tenant/twt/residential/1.jpg',
    short: 'HDR (High Dynamic Range) photos edited with our advanced digital blending technique.',
    bullets: [
      'Full Screen HD Tour Viewer',
      'Automated Walkthrough Video for YouTube',
      'MLS and Branded Tour Links',
      'Linking, Sharing and Embedding Tools',
      'Customizable Brochures',
    ],
    tenant: TenantId.TWIST_TOURS,
    addons: [TwtStagingConfig.id, TwtVirtualConfig.id, TwtEditingConfig.id],
    images: [],
  },
);

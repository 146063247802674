import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'editing',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Editing',
    short:
      'We magically convert a normal day photo into a twilight image, complete with different sky, window and light effects and more.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/video/1.jpg',
    images: [],
  },
);

import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'reel',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      voiceover: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Professional Social Media Reel',
    short:
      'Custom social media reel shot in vertical format, perfect for Instagram, TikTok, and Facebook Reels. Make your listing stand out from the crowd with this eye-catching 45-60 second video set to music.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/reel/thumbnail.png',
    images: [],
  },
);

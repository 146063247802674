import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'virtual',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      sky: z.string(),
      photos: z.coerce.number(),
    }),
  },
  {
    name: 'Virtual Twilight',
    short:
      'We magically convert a normal day photo into a twilight image, complete with different sky, window and light effects and more.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail: 'tenant/twt/twilight/virtual/1.tif',
    images: [],
  },
);
